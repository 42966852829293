import React from 'react';
import { Props } from './DataSourceCache';
import { Field, Switch, Input, Button, HorizontalGroup } from '@grafana/ui';
import { CacheSettingsDisable } from './CacheSettingsDisable';

interface CacheSettingsProps {
  loading: boolean;
  setUseDefaultTTL: (useDefaultTTL: boolean) => void;
  setTtlMs: (ttl: number) => void;
}

export const CacheSettingsForm = (props: Props & CacheSettingsProps) => {
  const {
    updateDataSourceCache,
    pageId,
    useDefaultTTL,
    setUseDefaultTTL,
    defaultTTLMs,
    ttlMs,
    setTtlMs,
    loading,
    enabled,
    dataSourceID,
    dataSourceUID,
  } = props;

  return (
    <div>
      <Field
        description="Enable this to use the default TTL set in Grafana's configuration"
        label="Use Default TTL"
        disabled={loading}
      >
        <Switch
          value={useDefaultTTL}
          onChange={() => {
            setUseDefaultTTL(!useDefaultTTL);
          }}
        />
      </Field>
      <Field
        description="The time-to-live for a single cache item in milliseconds. Example: 5000"
        label="TTL"
        disabled={loading || useDefaultTTL}
      >
        <Input
          type="number"
          className="max-width-10"
          placeholder="1000"
          min={0}
          value={(useDefaultTTL ? defaultTTLMs : ttlMs) || 0}
          onChange={(el) => {
            setTtlMs(el.currentTarget.valueAsNumber);
          }}
        />
      </Field>
      <HorizontalGroup spacing="md" align="flex-start" justify="flex-start">
        <CacheSettingsDisable {...props} />
        <Button
          disabled={loading}
          onClick={() =>
            updateDataSourceCache(pageId, {
              dataSourceID,
              dataSourceUID,
              enabled,
              defaultTTLMs,
              ttlMs,
              useDefaultTTL,
            })
          }
        >
          Save
        </Button>
      </HorizontalGroup>
    </div>
  );
};
